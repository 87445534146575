//================================== MIXINS ========================================

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

//================================== KEYFRAMES ========================================

@include keyframes(ori-fadeIn) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@include keyframes(ori-zoomIn) {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@include keyframes(ori-moveInLeft) {
  0% {
      opacity: 0;
      transform: translateX(-100px);
  }

  80% {
      transform: translateX(10px);
  }
  100% {
      opacity: 1;
      transform: translate(0);
  }
}

@include keyframes(ori-moveInRight) {
  0% {
      opacity: 0;
      transform: translateX(100px);
  }

  80% {
      transform: translateX(-10px);
  }
  
  100% {
      opacity: 1;
      transform: translate(0);
  }

}

//================================== CLASS NAMES ========================================

.ori-hoverableWrapper {
  .ori-onWrapperHoverHidden {
    display: none;
  }
  &:hover {
    .ori-onWrapperHoverHidden {
      display: block;
    }
  }
}

.ori-animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.ori-infinite {
  -webkit-animation-iteration-count: infinite !important;
  animation-iteration-count: infinite !important;
}

.ori-fadeIn {
  @include animation(ori-fadeIn 1s);
}

.ori-zoomIn {
  @include animation(ori-zoomIn 1s);
}

.ori-moveInLeft {
  @include animation(ori-moveInLeft 3s);
}

.ori-moveInRight {
  @include animation(ori-moveInRight 3s);
}
